import styled from 'styled-components'

export const Container = styled.article`
  padding: 0 20px;
  @media (min-width: 765px) {
    padding: 0 10%;
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const GoBack = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 16px;
  margin: 20px 0;
  cursor: pointer;
  align-self: flex-start;
  &:hover {
    text-decoration: underline;
  }
`

interface BannerTitleProps {
  bg: string
}

export const BannerTitle = styled.div<BannerTitleProps>`
  width: 100%;
  height: 250px;
  background-image: ${props => `url(${props.bg})`};
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;

  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${props => props.theme.colors.gradient.bottomRight};
    opacity: 0.8;
  }

  h2 {
    color: ${props => props.theme.colors.white};
    font-family: 'Roboto', sans-serif;
    z-index: 2;
    font-size: 32px;
    width: 80%;
    text-align: center;
  }

  div {
    color: ${props => props.theme.colors.white};
    font-family: 'Roboto', sans-serif;
    z-index: 2;
    font-size: 18px;
    width: 80%;
    text-align: center;
    margin-top: 5px;
  }
`

export const Article = styled.article`
  margin: 20px 0;
  font-family: 'Roboto Slab', serif;
  font-size: 16px;
  line-height: 1.4;

  p {
    margin-bottom: 15px;
  }

  @media (min-width: 765px) {
    width: 90%;
    font-size: 20px;
  }
`
