import React from 'react'
import * as dayjs from 'dayjs'
import { navigate } from 'gatsby'

import Layout from 'layouts'

import { Container, GoBack, BannerTitle, Article } from './posts.styles'

const Post = ({ data }: { data: any }) => {
  const { markdownRemark: post } = data

  const getImage = (path: string) => {
    const imagePath = require(`../../../content/blog/${path}`)

    return imagePath
  }

  return (
    <Layout>
      <Container>
        <GoBack onClick={() => navigate('/dicas-derosso')}>← voltar</GoBack>
        <BannerTitle bg={getImage(post.frontmatter.image.relativePath)}>
          <h2>{post.frontmatter.title}</h2>
          <div>{dayjs(post.frontmatter.date).format('DD/MM/YYYY')}</div>
        </BannerTitle>
        <Article dangerouslySetInnerHTML={{ __html: post.html }} />
      </Container>
    </Layout>
  )
}

export const postQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        description
        slug
        image {
          relativePath
          absolutePath
        }
      }
    }
  }
`

export default Post
